import type { ParsedUrlQuery } from 'querystring';
import { size } from 'constants/device';

import React from 'react';
import { useRouter } from 'next/router';
import { Button, Icons } from '@studenten/ui-components';
import { getSelectedFiltersList } from 'lib/filters';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import type { Props } from './types';
import {
  SelectedFiltersContainer,
  ClearButtonContainer,
  SelectedFiltersCard,
} from './SelectedFilters.styled';

export const SelectedFilters = ({
  filters,
  handleDeleteFilter,
  handleDeleteAllFilters,
  skipFilterKeys,
}: Props) => {
  const router = useRouter();
  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth >= size.laptopL;
  const selectedFilters = getSelectedFiltersList(filters, skipFilterKeys);

  const deleteFilter = async (key: string, item: string) => {
    const target = router.query[key];
    if (target) {
      const query: ParsedUrlQuery = {
        ...router.query,
        page: '1',
      };

      skipFilterKeys?.forEach((key) => delete query[key]);
      if (target instanceof Array) {
        query[key] = target.filter((query) => query !== item);
      } else {
        delete query[key];
      }

      router.push(
        {
          pathname: window?.location?.pathname,
          query,
        },
        undefined,
        { shallow: true }
      );
      await handleDeleteFilter(query, key, item);
    }
  };

  const clearAllFilters = async () => {
    const { query } = router.query;
    router.push(
      {
        pathname: window?.location?.pathname,
        query: query ? { query } : {},
      },
      undefined,
      { shallow: true }
    );
    if (typeof handleDeleteAllFilters === 'function') {
      await handleDeleteAllFilters(query ? { query } : {});
    }
  };

  if (!isDesktop) {
    return null;
  }

  return (
    <>
      {!!selectedFilters.length && (
        <SelectedFiltersContainer>
          {selectedFilters.map((item) => (
            <SelectedFiltersCard key={item.value + item.key}>
              <span>{item.label}</span>
              <Button variant="base" onClick={() => deleteFilter(item.key, item.value)}>
                <Icons.Cross size="medium" />
              </Button>
            </SelectedFiltersCard>
          ))}
          {typeof handleDeleteAllFilters === 'function' && (
            <ClearButtonContainer>
              <Button variant="base" onClick={clearAllFilters}>
                Alles wissen
              </Button>
            </ClearButtonContainer>
          )}
        </SelectedFiltersContainer>
      )}
    </>
  );
};
