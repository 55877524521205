import React from 'react';
import { Headings } from '@studenten/ui-components';
import Image from 'next/image';
import emptySearch from '../../../../public/assets/empty-search.svg';

import { EmptyState } from './EmptySearch.styled';

export const EmptySearch = () => {
  return (
    <EmptyState>
      <Image src={emptySearch} alt="art abstraction" />
      <div>
        <Headings.H2>Geen resultaten gevonden</Headings.H2>
        <p>Pas uw zoekopdracht aan:</p>
        <ul>
          <li>• Probeer verschillende zoektermen</li>
          <li>• Probeer meer algemene zoektermen</li>
        </ul>
      </div>
    </EmptyState>
  );
};
