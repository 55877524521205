import React, { useEffect } from 'react';
import { RadioButton, Button, Icons } from '@studenten/ui-components';

import {
  Container,
  GroupContent,
  GroupHeader,
  GroupItem,
  GroupItemCountLabelWrapper,
  GroupTitle,
  RotationAnimation,
} from './FilterBox.styled';
import { RatingProps, OptionItem } from './types';

export const RatingGroup = ({
  title,
  options,
  onChange,
  collapseable,
  ignoreField,
}: RatingProps) => {
  const [data, setData] = React.useState<RatingProps['options']>(options);
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = (item: OptionItem) => {
    data.forEach((_, key) => {
      data[key].checked = false;
      if (data[key].value === item.value && item.value !== ignoreField)
        data[key].checked = !item.checked;
    });
    setData([...data]);
    onChange && onChange(data);
  };

  useEffect(() => {
    setData(options);
  }, [options]);
  const isFilterHasChecked = data.some((item) => item.checked);

  return (
    <Container id={`filter-${title}`}>
      <GroupHeader>
        <GroupTitle>{title}</GroupTitle>
        {collapseable && (
          <Button width="auto" onClick={() => setCollapsed(!collapsed)}>
            <RotationAnimation angle={collapsed ? 180 : 0}>
              <Icons.UpTriangle />
            </RotationAnimation>
          </Button>
        )}
      </GroupHeader>
      <GroupContent numberOfElements={options.length} collapsed={collapsed}>
        {options.map((item) => (
          <GroupItem key={item.value}>
            <RadioButton
              value={item.value}
              name="rating-group"
              rating={Number(item.label.split(' ')[0])}
              disabled={item.counter === 0}
              checked={item.checked || (!isFilterHasChecked && item.value === 'All')}
              label={item.label}
              onChange={() => item.counter && toggle(item)}
            />
            <GroupItemCountLabelWrapper disabled={item.counter == 0}>
              <span>{item.counter}</span>
            </GroupItemCountLabelWrapper>
          </GroupItem>
        ))}
      </GroupContent>
    </Container>
  );
};
