import React, { useEffect, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Checkbox, InputField, Icons, Button } from '@studenten/ui-components';
import { useIntersection } from 'features/layout/hooks/useIntersection';

import type { Props, OptionItem } from './types';
import {
  Container,
  GroupHeader,
  GroupTitle,
  RotationAnimation,
  GroupContent,
  GroupItem,
  ShowMoreButtonWrapper,
  ShowMoreLabel,
  LabelContainer,
  LabelText,
  LabelCounter,
} from './FilterBox.styled';

export const FilterGroup = ({
  options = [],
  onChange,
  title,
  collapseable = false,
  filterPlaceholder,
  minFiltersToShow,
  hideCounters,
}: Props) => {
  const themeContext = useContext(ThemeContext);
  const [data, setData] = useState<Props['options']>(options);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [reduced, setReduced] = useState<boolean>(true);
  const reduceable = minFiltersToShow && options.length > minFiltersToShow;

  const toggle = (item: OptionItem) => {
    data.forEach((_, key) => {
      if (data[key].value === item.value) data[key].checked = !item.checked;
    });
    setData([...data]);
    onChange && onChange(data);
  };
  useEffect(() => {
    setData(options);
  }, [options]);

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  return (
    <Container id={`filter-${title}`} ref={intersectionRef}>
      <GroupHeader isCollapsable={collapseable} onClick={() => setCollapsed(!collapsed)}>
        <GroupTitle>{title}</GroupTitle>
        {collapseable && (
          <div>
            <Button width="auto">
              <RotationAnimation angle={collapsed ? 180 : 0}>
                <Icons.UpTriangle />
              </RotationAnimation>
            </Button>
          </div>
        )}
      </GroupHeader>

      <GroupContent
        collapsed={collapsed}
        numberOfElements={(reduceable && reduced ? minFiltersToShow : options.length) || 0}
      >
        {filterPlaceholder && (
          <GroupItem>
            <InputField
              prefix={<Icons.Search />}
              placeholder={filterPlaceholder}
              onChange={({ target }) => setInputValue(target.value)}
              value={inputValue}
            />
          </GroupItem>
        )}
        {options
          .filter((item) => item.value.toLowerCase().includes(inputValue.toLowerCase()))
          .slice(0, reduceable && reduced ? minFiltersToShow : options.length)
          .map((item, idx) => (
            <GroupItem
              key={item.value + idx}
              onClick={() => (item.checked || item.counter) && toggle(item)}
            >
              <Checkbox disabled={!item.counter && !item.checked} checked={!!item.checked}>
                <LabelContainer>
                  <LabelText checked={!!item.checked} disabled={!item.counter && !item.checked}>
                    {item.label}
                  </LabelText>
                  {!hideCounters && <LabelCounter>{item.counter}</LabelCounter>}
                </LabelContainer>
              </Checkbox>
            </GroupItem>
          ))}
        {reduceable &&
          options.filter((item) => item.value.toLowerCase().includes(inputValue.toLowerCase()))
            .length > Number(minFiltersToShow) && (
            <ShowMoreButtonWrapper>
              <Button
                variant="base"
                onClick={() => {
                  const searchSection = document.getElementById(`filter-${title}`);
                  if (intersection && intersection.intersectionRatio < 0.5) {
                    if (searchSection && !reduced) {
                      const top =
                        searchSection.getBoundingClientRect().top +
                        window.scrollY -
                        parseInt(themeContext.dimensions.headerHeight);
                      requestAnimationFrame(() =>
                        window.scrollTo({
                          top,
                        })
                      );
                    } else {
                      const top = window.scrollY;
                      requestAnimationFrame(() =>
                        window.scrollTo({
                          top,
                        })
                      );
                    }
                  }

                  setReduced(!reduced);
                }}
              >
                <ShowMoreLabel>{reduced ? 'Meer' : 'Minder'}</ShowMoreLabel>
                <RotationAnimation angle={reduced ? 180 : 0}>
                  <Icons.UpTriangle />
                </RotationAnimation>
              </Button>
            </ShowMoreButtonWrapper>
          )}
      </GroupContent>
    </Container>
  );
};
