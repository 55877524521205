import { device } from 'constants/device';

import { Headings } from '@studenten/ui-components';
import styled from 'styled-components';

export const Page = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.system.light};
  @media ${device.laptopL} {
    max-width: ${({ theme }) => theme.dimensions.laptopPageWidth};
  }
  @media ${device.laptop} {
    padding-top: 16px;
    max-width: calc(100% - 32px);
    padding-bottom: 80px;
  }
`;

export const MobileAutoSearchWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin: 32px 0;
  }
`;

export const FiltersAndResults = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Results = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ResultSection = styled.div`
  padding-bottom: 40px;
  @media ${device.laptop} {
    padding-bottom: 30px;
  }
`;

export const ResultsList = styled.div`
  overflow: visible;
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const ResultItem = styled.div`
  margin: 20px 0;
  @media ${device.tablet} {
    margin: 8px 0;
    & > a > div {
      width: 90vw;
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;

export const ResultsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${Headings.H2} {
    color: ${({ theme }) => theme.brand.blue};
  }
`;

export const MobileShowAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 2px;
`;
