import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
    @media ${device.tablet} {
      margin-top: 32px;
    }
  }
`;

export const GroupHeader = styled.div<{ isCollapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: ${({ isCollapsable }) => (isCollapsable ? 'auto' : 'none')};
  cursor: pointer;
`;

export const GroupContent = styled.div<{ collapsed: boolean; numberOfElements: number }>`
  margin-top: 20px;
  max-height: ${({ collapsed, numberOfElements }) =>
    collapsed ? '0' : `${40 * numberOfElements + 150}px`};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

export const GroupItem = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const GroupItemCountLabelWrapper = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: right;
    color: ${({ theme, disabled }) =>
      disabled ? theme.system.darkGreyNonActive : theme.system.darkGreyActive};
    @media ${device.tablet} {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
`;

export const ShowMoreButtonWrapper = styled.div`
  margin-top: 16px;
  width: 142px;
  & > button {
    background: ${({ theme }) => theme.system.lightGrey};
    border-radius: 7px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 19px;
    padding: 8px 16px;
    color: ${({ theme }) => theme.brand.blue};
    & > div {
      display: inline-block;
    }
  }
`;

export const ShowMoreLabel = styled.span`
  margin-right: 6px;
`;

export const GroupTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.system.black};
  @media ${device.laptop} {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`;

export const LabelContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  padding-left: 16px;

  font-size: 1.6rem;
  line-height: 1.9rem;

  @media ${device.tablet} {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`;

export const LabelText = styled.span<{ checked: boolean; disabled: boolean }>`
  flex: 1;
  padding-right: 30px;
  min-height: 22px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: ${({ checked }) => (checked ? 600 : 500)};
  color: ${({ theme, checked, disabled }) => {
    if (disabled) {
      return theme.system.darkGreyNonActive;
    } else if (checked) {
      return theme.brand.blue;
    } else {
      return theme.system.black;
    }
  }};
  font-size: 1.6rem;
  line-height: 2.2rem;

  @media ${device.tablet} {
    min-height: 1.8rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;
export const LabelCounter = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  min-height: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.system.darkGreyActive};
  font-size: 1.6rem;
  line-height: 2.2rem;
  @media ${device.tablet} {
    min-height: 1.8rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const RotationAnimation = styled.div<{ angle: number }>`
  display: inline-flex;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: rotate(${({ angle }) => angle || 0}deg);
`;
