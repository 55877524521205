import { device } from 'constants/device';

import styled from 'styled-components';

import type { Props } from './types';

export const Container = styled.div`
  position: relative;
`;

export const ImagePlaceholder = styled.div<{ height?: string | number; width?: string | number }>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-color: ${({ theme }) => theme.system.white};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-position: left top;
  }
  /* border-radius: 12px;
  border: ${({ theme }) =>
    `2px solid ${theme.system.lightGrey}`};
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  color: ${({
    theme,
  }) => theme.system.darkGreyActive};
  */
`;

export const Image = styled.img<Props>`
  height: ${(props) => props.height || 'auto'};
  object-fit: ${(props) => props.fit || 'cover'};
  width: ${(props) => props.width};
  border-radius: 12px;
`;

export const ImageOverlay = styled.div<{ height?: string | number }>`
  z-index: 2;
  position: absolute;
  top: 0;
  height: ${(props) => props.height};
  width: 100%;
  border-radius: 12px;
  opacity: 0;
  transition: 0.1s ease;
  background-color: #3c3e45;
  @media ${device.laptop} {
    display: none;
  }
`;

export const FavoriteButtonWrapper = styled.div<Pick<Props, 'isFavorite'>>`
  display: ${({ isFavorite }) => (isFavorite ? 'inline-block' : 'none')};
  position: absolute;
  top: 18px;
  left: 18px;
  width: 18px;
  height: 21px;
  z-index: 2;

  button {
    padding: 2px;
    @media ${device.laptop} {
      display: none;
    }
  }
  svg {
    fill: ${({ isFavorite, theme }) => (isFavorite ? theme.system.red : 'none')};
    stroke: ${({ isFavorite, theme }) => (isFavorite ? theme.system.red : theme.system.white)};
  }
`;
