import Head from 'next/head';
import { useRouter } from 'next/router';

type MetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
};

export const MetaTags = ({
  title = 'Studenten.nl: Universiteiten, Traineeships en bijbanen',
  description = 'Studenten.nl is een online platform voor het vinden van een opleiding, bijbaan of traineeship. Met een aanbod van 3000+ opleidingen en 12000+ bijbanen het Studentenplatform van Nederland.',
  image = 'https://studenten.nl/assets/studenten-og.png',
}: MetaTagsProps) => {
  const router = useRouter();
  return (
    <Head>
      <>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="Studenten.nl" />
        <meta property="og:url" content={`https://studenten.nl${router?.asPath}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="locale" content="nl" />
      </>
    </Head>
  );
};
