import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  @media ${device.tablet} {
    margin-top: 33px;
    margin-bottom: 16px;
  }
`;

export const PageTitleWrapper = styled.div`
  width: 400px;
  color: ${({ theme }) => theme.brand.blue};
  @media ${device.laptopL} {
    width: auto;
  }
`;

export const SearchResultsWrapper = styled.div`
  margin-left: 20px;
  flex: 1;
  @media ${device.laptopL} {
    justify-content: flex-end;
    flex: initial;
  }
`;

export const SearchResultsNumber = styled.span`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.system.black};
  @media ${device.tablet} {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`;
