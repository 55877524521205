import React from 'react';

import { Text } from './StyledTitle';

export type Props = {
  children: React.ReactNode;
  maxLineCount: number;
  size?: string;
};

export const Title = ({ children, maxLineCount = 2, size = '2rem' }: Props) => {
  return (
    <Text maxLineCount={maxLineCount} size={size}>
      {children}
    </Text>
  );
};
