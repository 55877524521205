import { size } from 'constants/device';

import React from 'react';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import { Icons } from '@studenten/ui-components';

import { Container, Text, IconWrapper } from './Location.styled';

type Props = {
  name: string | Array<string>;
};

export const Location = ({ name }: Props) => {
  const { width } = useWindowSize();
  const iconSize = width <= size.tablet ? 'small' : 'medium';
  return (
    <Container>
      <IconWrapper>
        <Icons.MapPointer size={iconSize} />
      </IconWrapper>
      <Text>{Array.isArray(name) ? [...new Set(name)].join(', ') : name}</Text>
    </Container>
  );
};
