import { device } from 'constants/device';

import styled from 'styled-components';

export const MobileFiltersButtonWrapper = styled.div`
  display: none;
  margin-bottom: 24px;
  @media ${device.laptopL} {
    display: block;
  }
  & > button {
    width: auto;
    min-width: 97px;
    height: 42px;
    background: ${({ theme }) => theme.system.light};
    border: 1px solid ${({ theme }) => theme.brand.blue};
    box-sizing: border-box;
    border-radius: 8px;
    color: ${({ theme }) => theme.brand.blue};
    svg {
      margin-right: 8px;
    }
  }
`;
