import React from 'react';
import { Headings } from '@studenten/ui-components';

import {
  Container,
  PageTitleWrapper,
  SearchResultsWrapper,
  SearchResultsNumber,
} from './SearchResultsHeader.styled';

type Props = {
  title: string;
  totalCount?: number;
};

export const SearchResults = ({ title, totalCount }: Props) => {
  return (
    <Container>
      <PageTitleWrapper>
        <Headings.H2>{title}</Headings.H2>
      </PageTitleWrapper>
      {!!totalCount && (
        <SearchResultsWrapper>
          <SearchResultsNumber>{totalCount.toLocaleString()} resultaten</SearchResultsNumber>
        </SearchResultsWrapper>
      )}
    </Container>
  );
};
