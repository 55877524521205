import styled from 'styled-components';

export const EmptyState = styled.div`
  display: grid;
  place-items: center;
  margin-top: 80px;
  margin-bottom: 100px;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 160%;
  color: ${({ theme }) => theme.system.black};
  text-align: left;
  & > div {
    max-width: 400px;
    & > h2 {
      text-align: center;
      margin: 32px 0;
    }
    & > p {
      margin-bottom: 16px;
    }
  }
`;
