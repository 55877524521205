import { size } from 'constants/device';

import { useRef } from 'react';
import { useRouter } from 'next/router';
import { RemoveScroll } from 'react-remove-scroll';
import { Drawer, Icons, Overlay, Button } from '@studenten/ui-components';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';
import { getSelectedFiltersList } from 'lib/filters';
import { observer } from 'mobx-react';

import FilterBox from './components/FilterBox';
import type { Props } from './types';
import {
  Container,
  MobileHeader,
  MobileApplyButtonWrapper,
  MobileResultsCounterWrapper,
  HeaderResultsContainer,
  ClearAllFiltersButtonWrapper,
} from './SearchFilters.styled';

export const SearchFilters = observer(
  ({
    filters,
    store,
    totalCount,
    isOpen,
    setOpen,
    skipFilterKeys,
    transformQuery,
    handleDeleteAllFilters,
    hideCounters,
  }: Props) => {
    const router = useRouter();
    const drawerRef = useRef<HTMLDivElement | null>(null);
    const { width: windowWidth } = useWindowSize();
    const closeDrawer = () => setOpen(false);
    useOutsideClick(drawerRef, closeDrawer);
    const isDesktop = windowWidth >= size.laptopL;

    const clearAllFilters = async () => {
      if (typeof handleDeleteAllFilters === 'function') {
        handleDeleteAllFilters();
      } else {
        const { query } = router.query;
        router.push(
          {
            pathname: window?.location?.pathname,
            query: query ? { query } : {},
          },
          undefined,
          { shallow: true }
        );
        if (typeof store.deleteAllSelectedFilters === 'function') {
          await store.deleteAllSelectedFilters(query ? { query } : {});
        }
      }
    };

    const renderFiltersCaterory = () => {
      return filters.map((filter, i) => {
        if (filter.type !== 'filter') {
          return null;
        }

        const FilterTagName =
          filter.type === 'filter' ? FilterBox.FilterGroup : FilterBox.RatingGroup;
        return (
          <FilterTagName
            hideCounters={hideCounters}
            key={filter.title}
            options={store.filters[filter.key]}
            title={filter.title}
            collapseable={i > 2}
            minFiltersToShow={filter.minFiltersToShow}
            filterPlaceholder={filter.filterPlaceholder}
            ignoreField={filter.ignoreField}
            onChange={async (data) => {
              store.toggleFilters(data, filter.key);
              const query = {
                ...router.query,
                page: '1',
                [filter.key]: data
                  .filter((element) => element.checked)
                  .map((element) => element.value),
              };

              await router.push(
                {
                  pathname: router.pathname,
                  query,
                },
                undefined,
                { shallow: true }
              );
              if (typeof transformQuery === 'function') {
                transformQuery(query);
              }
              if ('requestIdleCallback' in window) {
                window.requestIdleCallback(async () => {
                  await store.fetchFilters(query, filter.key);
                });
              } else {
                await store.fetchFilters(query, filter.key);
              }
            }}
          />
        );
      });
    };

    if (isDesktop) {
      return <Container>{renderFiltersCaterory()}</Container>;
    }

    return (
      <>
        <Overlay isOpen={isOpen} />
        <RemoveScroll enabled={isOpen}>
          <Drawer isOpen={isOpen} ref={drawerRef}>
            <MobileHeader>
              <Button variant="base" onClick={closeDrawer}>
                <Icons.Cross size="large" />
              </Button>
              <HeaderResultsContainer>
                <MobileResultsCounterWrapper>
                  <span>{totalCount} resultaten</span>
                </MobileResultsCounterWrapper>
                {Boolean(getSelectedFiltersList(store.filters, skipFilterKeys).length) && (
                  <ClearAllFiltersButtonWrapper>
                    <Button variant="base" onClick={clearAllFilters}>
                      Alles wissen
                    </Button>
                  </ClearAllFiltersButtonWrapper>
                )}
              </HeaderResultsContainer>
            </MobileHeader>
            <Container>{renderFiltersCaterory()}</Container>
            <MobileApplyButtonWrapper>
              <Button variant="primary" onClick={closeDrawer}>
                Apply
              </Button>
            </MobileApplyButtonWrapper>
          </Drawer>
        </RemoveScroll>
      </>
    );
  }
);
