import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const usePopRouterEventNotShallow = () => {
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(({ url, as }) => {
      if (Object.keys(router.query).length) {
        router.push(url, undefined, { shallow: false, scroll: false }).catch(() => {
          router.push({ pathname: as }, undefined, { shallow: false });
        });

        return false;
      }
      return true;
    });
  }, [router.query]);
};
