import React from 'react';
import { Button, Icons } from '@studenten/ui-components';
import { FilterItem } from 'lib/filters/types';
import { getSelectedFiltersList } from 'lib/filters';

import { MobileFiltersButtonWrapper } from './MobileFiltersOpenButton.styled';

type Props = {
  onFilterOpen: () => void;
  filters: Record<string, Array<FilterItem>>;
  skipfilterKeys?: Array<string>;
};

export const MobileFiltersOpenButton = ({ onFilterOpen, filters, skipfilterKeys }: Props) => {
  const selectedFiltersCount = getSelectedFiltersList(filters, skipfilterKeys).length;
  return (
    <MobileFiltersButtonWrapper>
      <Button variant="base" onClick={onFilterOpen}>
        <span>
          <Icons.Filters />
          Filters {selectedFiltersCount ? `(${selectedFiltersCount})` : null}
        </span>
      </Button>
    </MobileFiltersButtonWrapper>
  );
};
