import React, { useState } from 'react';
import { Icons, Button } from '@studenten/ui-components';
import { parseImageUrl } from '../../../../../../utils/helpers';

import commonFallback from '../../../../../../public/assets/placeholders/placeholder-9.svg';

import type { Props } from './types';
import {
  Image,
  Container,
  FavoriteButtonWrapper,
  ImageOverlay,
  ImagePlaceholder,
} from './Picture.styled';

//TODO: rewrite with picture tag
export const Picture = (props: Props) => {
  const [isError, setError] = useState<boolean | null>(null);
  const { fallBackSRC, src, ...imageProps } = props;
  const withFavorite = props.onFavoriteClick;
  return (
    <Container>
      <div>
        {src && !isError ? (
          <Image
            onError={() => setError(true)}
            {...parseImageUrl(src)}
            {...imageProps}
            alt="preview"
          />
        ) : (
          <ImagePlaceholder {...props}>
            <Image src={parseImageUrl(fallBackSRC || commonFallback).src} alt="art abstraction" />
          </ImagePlaceholder>
        )}
        {withFavorite && <ImageOverlay height={props.height} />}
      </div>
      {withFavorite && (
        <FavoriteButtonWrapper isFavorite={props.isFavorite}>
          <Button
            variant="base"
            onClick={(e) => props.onFavoriteClick && props.onFavoriteClick(e)}
            className="like-button"
          >
            <Icons.Bookmark />
          </Button>
        </FavoriteButtonWrapper>
      )}
    </Container>
  );
};
