import { device } from 'constants/device';

import styled from 'styled-components';

import { Props } from './Title';

export const Text = styled.span<Props>`
  font-weight: bold;
  font-size: ${(props) => props.size};
  line-height: 140%;
  color: ${({ theme }) => theme.system.black};
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.maxLineCount};
  display: -webkit-box;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
