import styled from 'styled-components';

export const SelectedFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 20px;
  margin-top: -10px;
`;

export const SelectedFiltersCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.system.darkGreyActive};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.system.darkGreyActive};
  padding: 12px;
  height: 42px;
  white-space: nowrap;
  margin-right: 18px;
  margin-top: 10px;
  button {
    padding: 0;
    color: ${({ theme }) => theme.system.darkGreyActive};
  }
`;
export const ClearButtonContainer = styled.div`
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  button {
    color: ${({ theme }) => theme.system.red};
  }
`;
