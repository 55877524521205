import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
`;
export const Text = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  color: ${({ theme }) => theme.system.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  @media ${device.tablet} {
    font-size: 10px;
  }
`;
